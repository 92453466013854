<template>
  <ui-list
    :heading="heading"
    :headings="[$t('fields.name'), $t('misc.completion'), 'search']"
    :items="companies"
    :sort="[
      { prop: 'name', type: false },
      { prop: 'progress', prop2: 'progressPercentage', type: 'level2' }
    ]"
    :search_selectors="['name']"
  >
    <template v-slot:default="props">
      <ui-list-data :text="props.item.name" :info="props.item.description">
      </ui-list-data>

      <template>
        <ui-list-data-progress
          v-if="typeof getPercentage(props.item) === 'number'"
          :percentage="getPercentage(props.item)"
          :progressDetails="props.item.progress"
        >
        </ui-list-data-progress>
        <ui-list-data
          v-if="getPercentage(props.item) === false"
          :text="$t('labels.course.no_users_assigned')"
        >
        </ui-list-data>
      </template>

      <ui-list-data small>
        <ui-button
          type="green"
          class="show_content_button"
          size="small"
          uppercase
          @click="
            $router.push({
              name: 'companies-show',
              params: { id: props.item.id }
            })
          "
          >{{ $t('actions.show_content') }}</ui-button
        >
        <ui-button
          type="white"
          class="ml-2"
          size="small"
          uppercase
          @click="
            $router.push({
              name: 'companies-users',
              params: { id: props.item.id }
            })
          "
          >{{ $tc('labels.user.company_admin', 2) }}</ui-button
        >
        <auth-checker :accessRoles="['SuperAdmin', 'CompanyAdmin']">
          <ui-button
            class="ml-2"
            type="grey"
            size="small"
            uppercase
            @click="
              $router.push({
                name: 'companies-edit',
                params: { id: props.item.id }
              })
            "
            >{{ $t('actions.edit') }}</ui-button
          >
        </auth-checker>
      </ui-list-data>
    </template>
  </ui-list>
</template>

<script>
import UiList from '@/components/ui/UiList';
import UiListData from '@/components/ui/UiListData';
import UiListDataProgress from '@/components/ui/UiListDataProgress';
import AuthChecker from '../../auth/components/AuthChecker';
import UiButton from "@/components/ui/UiButton";

export default {
  props: {
    heading: {
      type: String
    },
    companies: {
      type: Array,
      required: true
    }
  },

  components: {
    UiButton,
    UiList,
    UiListData,
    AuthChecker,
    UiListDataProgress
  },

  methods: {
    getPercentage(item) {
      if (item.progress && item.progress.assignedCount) {
        return item.progress.progressPercentage;
      } else {
        if (item.assignedCount) {
          const per = Math.floor(
            (item.completedCount / item.assignedCount) * 100
          );
          return per;
        } else {
          return false;
        }
      }
    }
  }
};
</script>

<style>

.company_users_button {
  border: black 1px solid;
}

</style>
